import { useState } from 'react';
import './App.scss';

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyDEaJM5pv8GmD0D0lFWNKAx4NVVM_k9hV8',
  authDomain: 'portfolio-c6492.firebaseapp.com',
  projectId: 'portfolio-c6492',
  storageBucket: 'portfolio-c6492.appspot.com',
  messagingSenderId: '525337429452',
  appId: '1:525337429452:web:51ea9d2523bbdac11b0969',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const landing = [
  {
    id: 0,
    title: 'Discovery TV App',
    video: '/videos/discovery.mp4',
    tags: [
      'Frontend and Backend in Javascript',
      'React',
      'Firebase',
      'Api Intergration',
      'CTV (Large Format for Television)',
      'UX',
      'Javascript',
    ],
  },
  {
    id: 1,
    title: 'Lucid Web App',
    video: '/videos/lucid.mp4',
    link: 'https://lucidtracking.com',
    tags: [
      'Frontend and Backend in Javascript',
      'React',
      'Google Appscript and Ecosystem',
      'Api Intergration',
      'UX',
      'Data Viz',
      'Visual Design and Branding',
    ],
  },
  {
    id: 2,
    title: 'DC Comics Shop (Warner Bros)',
    video: '/videos/dcshop.mp4',
    link: 'https://shop.dc.com',
    className: 'portrait',
    tags: [
      'Frontend and Backend in Javascript',
      'NextJS',
      'AWS Serverless',
      'UX',
      'Api Integration',
    ],
  },
  {
    id: 3,
    title: 'Functional Medicine App',
    video: '/videos/fmd.mp4',
    link: 'https://the-fmd.com',
    className: 'portrait',
    tags: [
      'Frontend and Backend in Javascript',
      'React',
      'Firebase',
      'Api Intergration',
      'UX',
      'Data Viz',
      'Visual Design and Branding',
    ],
  },
  {
    id: 4,
    title: 'Data Visualization',
    video: '/videos/network.mp4',
    tags: ['Frontend in Javascript', 'React'],
  },
];

function App() {
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  return (
    <div className="App">
      <div className="header">
        <div>
          <h1>Enrique Florendo</h1>
          <p className="faded">
            Software Engineer with Design Chops (Design Technologist) based in
            CA
          </p>
        </div>
        <div className="contact">
          <a href="mailto:enriqueflorendo@gmail.com">
            <img src="/mail.svg" />
          </a>
          <a href="tel:6174073971">
            <img src="/call.svg" />
          </a>
        </div>
      </div>
      {selectedProjectId !== null && (
        <img
          src="/close.svg"
          className="close"
          alt="Close"
          onClick={() => setSelectedProjectId(null)}
        />
      )}
      {landing.map((l) => (
        <div
          className={`hero ${selectedProjectId === l.id ? 'fullscreen' : ''}`}
        >
          <h2>
            {l.title}{' '}
            <span className="actions">
              <div>
                <img
                  src="/expand.svg"
                  className="open"
                  alt="Expand"
                  title="Full Screen"
                  onClick={() => setSelectedProjectId(l.id)}
                />
              </div>
              {l.link && (
                <div>
                  <a href={l.link} target="_blank" rel="noreferrer">
                    <img
                      src="/open.svg"
                      className="open"
                      alt="Visit App"
                      title="Visit App"
                    />
                  </a>
                </div>
              )}
            </span>
          </h2>

          <div className="tags">
            {l.tags.map((t) => (
              <span className="tag">{t}</span>
            ))}
          </div>
          <video
            className={l.className ? l.className : ''}
            src={l.video}
            playsInline
            loop
            autoPlay
            muted
            onClick={() => setSelectedProjectId(null)}
          />
        </div>
      ))}
    </div>
  );
}

export default App;
